import { type IconProps, Icon } from './icon.js';

export function SavedPlaylist(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Save Playlist" {...props}>
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3346 12.0013L4.0013 12.0013C3.26797 12.0013 2.66797 12.6013 2.66797 13.3346C2.66797 14.068 3.26797 14.668 4.0013 14.668L17.3346 14.668C18.068 14.668 18.668 14.068 18.668 13.3346C18.668 12.6013 18.068 12.0013 17.3346 12.0013ZM17.3346 6.66797L4.0013 6.66797C3.26797 6.66797 2.66797 7.26797 2.66797 8.0013C2.66797 8.73464 3.26797 9.33464 4.0013 9.33464L17.3346 9.33464C18.068 9.33464 18.668 8.73464 18.668 8.0013C18.668 7.26797 18.068 6.66797 17.3346 6.66797ZM24.0013 17.3346L24.0013 13.3346C24.0013 12.6013 23.4013 12.0013 22.668 12.0013C21.9346 12.0013 21.3346 12.6013 21.3346 13.3346L21.3346 17.3346H17.3346C16.6013 17.3346 16.0013 17.9346 16.0013 18.668C16.0013 19.4013 16.6013 20.0013 17.3346 20.0013H21.3346V24.0013C21.3346 24.7346 21.9346 25.3346 22.668 25.3346C23.4013 25.3346 24.0013 24.7346 24.0013 24.0013V20.0013L28.0013 20.0013C28.7346 20.0013 29.3346 19.4013 29.3346 18.668C29.3346 17.9346 28.7346 17.3346 28.0013 17.3346L24.0013 17.3346ZM4.0013 20.0013H12.0013C12.7346 20.0013 13.3346 19.4013 13.3346 18.668C13.3346 17.9346 12.7346 17.3346 12.0013 17.3346H4.0013C3.26797 17.3346 2.66797 17.9346 2.66797 18.668C2.66797 19.4013 3.26797 20.0013 4.0013 20.0013Z" />
      </svg>
    </Icon>
  );
}
